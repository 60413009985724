import React, { useEffect, useState } from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import ItemCard from '../comps/ItemCard';
import Header from '../comps/Header';
import css from '../../styles/main.css';
import { useSelector } from 'react-redux';

const { ItemDiv } = css;


const Items = () => {
    const [categories, setCategories] = useState([]);
    const apiUrl = config.apiURL

    useEffect(() => {
      const categoriesUrl = apiUrl + 'categories/' + BotId;
      const updateCategories = async () => {
        const result = await axios.get(categoriesUrl);
        setCategories(result.data.categories);
      };

      updateCategories();
      scroll.scrollTo(20)
    }, []);

    const [params, ] = useSearchParams();
    const BotId = params.get('botId');
    const type = params.get('type');
    const cart = useSelector((state) => state.cart.cart);
    const tg = window.Telegram.WebApp;
    const backButton = tg.BackButton;
    const navigate = useNavigate();
    backButton.show();
    backButton.onClick(function () {
        navigate(-1);
        backButton.hide();
    });

    const mainButton = tg.MainButton;
    mainButton.color = "#62C751"
    mainButton.textColor = "#FFFFFF"

    const goToCart = () => {
        navigate(`/itemCart?botId=${BotId}&type=${type}`);
    }

    useEffect(() => {
        if (Object.keys(cart).length > 0) {
            mainButton.text = "Посмотреть заказ"
            mainButton.onClick(goToCart);
            mainButton.show();
            return () => {
                mainButton.offClick(goToCart);
            }
        } else {
            mainButton.hide();
        }
    }, [cart]);

    return (
        <React.Fragment>
            <ItemDiv>
                <Header categories={categories} />
                <ItemCard categories={categories}/>
            </ItemDiv>
        </React.Fragment>
    )
}


export default Items;

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import css from '../../styles/main.css';
import Card from '../comps/Card';
import { config } from '../../config';
import { useDispatch } from "react-redux";

import preCafeImg from '../../img/pre_cafe.jpg';
import preDeliveryImg from '../../img/pre_delivery.jpg';
import preSelfImg from '../../img/pre_self.jpg';
import {addUser} from "../../redux-state/reducers/Info";

const { StartTitle, StartCardContainer, RouterLink, StartDiv } = css;


const Start = () => {
    const [params, ] = useSearchParams();
    const BotId = params.get('botId');
    const domainURL = config.domainURL;
    const cafeUrl = domainURL + 'items?botId=' + BotId + '&type=cafe';
    const deliveryUrl = domainURL + 'items?botId=' + BotId + '&type=delivery';
    const selfUrl = domainURL + 'items?botId=' + BotId + '&type=self';

    const tg = window.Telegram.WebApp;
    const userId = tg.initDataUnsafe.user.id;
    const dispatch = useDispatch();
    dispatch(addUser(userId));

    return (
        <React.Fragment>
            <StartDiv>
                <StartTitle>Здравствуйте!<br/>Выберите способ заказа</StartTitle>
                <StartCardContainer>
                    <RouterLink to={cafeUrl}>
                        <Card  img={preCafeImg} title="В кафе" />
                    </RouterLink>
                    <RouterLink to={deliveryUrl}>
                        <Card img={preDeliveryImg} title="Доставка" />
                    </RouterLink>
                    <RouterLink to={selfUrl}>
                        <Card img={preSelfImg} title="Самовывоз" />
                    </RouterLink>
                </StartCardContainer>
            </StartDiv>
        </React.Fragment>
    );
}


export default Start;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalFonts from './styles/fonts';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './redux-state/store';
import 'reset-css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const script = document.createElement('script');
script.src = "https://telegram.org/js/telegram-web-app.js"
script.async = false;
document.head.appendChild(script);

script.addEventListener('load', () => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <GlobalFonts />
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
});

import { Routes, Route } from "react-router-dom";
import Start from "./components/pages/Start";
import Items from "./components/pages/Items";
import ItemInfo from "./components/pages/ItemInfo";
import ItemCart from "./components/pages/ItemCart";
import React from "react";

function App() {

  return (
    <React.Fragment style={{ backgroundColor: "var(--tg-theme-bg-color)" }}>
      <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/items" element={<Items />} />
          <Route path="/itemInfo/:itemId" element={<ItemInfo />} />
          <Route path="/itemCart" element={<ItemCart />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;

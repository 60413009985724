import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const css = {
    StartDiv: styled.div`
      width: 100%;
      min-height: 100vh;
      background-color: var(--tg-theme-bg-color);
      `,
    StartTitle: styled.h3`
      padding-top: 30px;
      margin-bottom: 30px;
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      font-size: 16px;
      color: var(--tg-theme-text-color);
      text-align: center;
    `,
    StartCardContainer: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    `,
    StartCard: styled.div`
        display: block;
        background-color: #fff;
        border-radius: 10px;
        padding: 15px;
        box-sizing: border-box;
        margin: 10px;
    `,
    StartCardImg: styled.img`
        border-radius: 10px;
        width: 100px;
        height: 100px;
    `,
    StartCardTitle: styled.div`
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        margin-top: 15px;
        text-align: center;
    `,
    RouterLink: styled(Link)`
        text-decoration: none;
        color: inherit;
    `,
    ItemDiv: styled.div`
      width: 100%;
      min-height: 100vh;
      background-color: var(--tg-theme-bg-color);
    `,
    HeaderNav: styled.div`
        position: sticky;
        top: 0;
        background-color: var(--tg-theme-bg-color);
        z-index: 100;
        overflow-x: auto;
    `,
    HeaderButtonContainer: styled.div`
        display: flex;
        padding: 8px 0 8px 8px;
    `,

    HeaderButton: styled(ScrollLink)`
      align-items: center;
      padding: 7px 11px;
      opacity: 0.6;
      border: 1px solid;
      border-color: var(--tg-theme-hint-color);
      color: var(--tg-theme-text-color);
      background-color: var(--tg-theme-bg-color);
      border-radius: 100px;
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      margin-right: 10px;

      &.active {
        background-color: var(--tg-theme-text-color);
        color: var(--tg-theme-bg-color);
        border-color: var(--tg-theme-bg-color);
    `,
    CategoryTitle: styled.h2`
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        font-size: 23px;
        color: var(--tg-theme-text-color);
        padding: 0 10px;
        margin-top: 8px;
        margin-bottom: 8px;
    `,
    ItemRow: styled.div`
        display: block;
        padding: 12px;
    `,
    ItemContainer: styled.div`
        display: flex;
        align-items: center;
    `,
    ItemImg: styled.img`
        width: 90px;
        height: 90px;
        border-radius: 12px;
        display: block;
    `,
    ItemInfo: styled.div`
        flex-grow: 1;
    `,
    ItemTitle: styled.div`
      font-family: 'roboto', sans-serif;
      font-weight: bold;
      font-size: 16px;
      color: var(--tg-theme-text-color);
      margin-left: 10px;
    `,
    ItemDescription: styled.div`
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      font-size: 14px;
      margin-top: 5px;
      margin-left: 10px;
      color: var(--tg-theme-text-color);
    `,
    ItemPriceContainer: styled.div`
        text-align: center;
    `,
    ItemPrice: styled.div`
        margin-bottom: 5px;
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        font-size: 16px;
        box-sizing: border-box;
        padding-left: 15px;
        max-width: 100%;
        color: var(--tg-theme-text-color);
    `,
    ItemButton: styled.button`
        background-color: var(--tg-theme-button-color);
        border-radius: 10px;
        border: none;
        color: var(--tg-theme-button-text-color);
        font-family: 'roboto', sans-serif;
        font-weight: normal;
        font-size: 12px;
        padding: 7px 11px;
        height: 30px;
    `,
    ItemCartButtonContainer: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `,
    ItemCartButton: styled.button`
        color: white;
        width: 30px;
        height: 30px;
        text-align: center;
        border: 0;
        border-radius: 10px;
        font-size: 12px;
        font-family: 'roboto', sans-serif;
        font-weight: bold;

        &.minus {
            background-color: #FB923C;
        }
        &.plus {
            background-color: #62C751;
        }
    `,
    ItemCartQuantity: styled.span`
        font-size: 16px;
        color: var(--tg-theme-text-color);
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        margin: 0 5px;
    `,
    ItemIDiv: styled.div`
        width: 100%;
        min-height: 100vh;
        background-color: var(--tg-theme-bg-color);
    `,
    ItemIImg: styled.img`
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
        vertical-align: middle;
    `,
    ItemIContainer: styled.div`
      display: flex;
      flex-direction: column;
      padding: 10px;
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      color: var(--tg-theme-text-color);
    `,
    ItemITitle: styled.h3`
        font-weight: bold;
        font-size: 20px;
    `,
    ItemIDescription: styled.div` 
        font-size: 16px;
        margin-top: 10px;
    `,
    ItemIComposition: styled.div`
      font-size: 16px;
      margin-top: 10px;
      color: var(--tg-theme-hint-color);
    `,
    CartDiv: styled.div`
        width: 100%;
        min-height: 100vh;
        background-color: var(--tg-theme-bg-color);
    `,
    CartInner: styled.div`
      padding: 12px;
      display: block;
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      color: var(--tg-theme-text-color);
    `,
    CartHeader: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    `,
    CartTitle: styled.h2`
      font-weight: bold;
      font-size: 28px;
      color: var(--tg-theme-text-color);
    `,
    CartTrash: styled.img`
      width: 24px;
      height: 24px;
    `,
    CartItems: styled.div`
        display: block;
    `,
    CartItem: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    `,
    CartItemImg: styled.img`
        width: 90px;
        height: 90px;
        border-radius: 12px;
        display: block;
    `,
    CartItemTitle: styled.h3`
        font-weight: bold;
        font-size: 16px;
        margin-left: 10px;
    `,
    CartItemPriceContainer: styled.div`
        text-align: center;
    `,
    CartItemPrice: styled.div`
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 16px;
        box-sizing: border-box;
        padding-left: 15px;
        max-width: 100%;
    `,
    CartItemButton: styled.button`
        background-color: var(--tg-theme-button-color);
        border-radius: 10px;
        border: none;
        color: var(--tg-theme-button-text-color);
        font-family: 'roboto', sans-serif;
        font-weight: normal;
        font-size: 12px;
        padding: 7px 11px;
        height: 30px;
    `,
    CartItemCartButtonContainer: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `,
    CartItemCartButton: styled.button`
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 10px;
        font-size: 12px;
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        background-color: var(--tg-theme-bg-color);
        
        &.minus {
          color: #FB923C;
          border: 1px solid #FB923C;
        }
        &.plus {
            color: #62C751;
            border: 1px solid #62C751;
        }
    `,
    CartItemCartQuantity: styled.span`
        font-size: 16px;
        color: var(--tg-theme-text-color);
        font-family: 'roboto', sans-serif;
        font-weight: bold;
        margin: 0 5px;
    `,
    OrderTypeHeader: styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--tg-theme-button-color);
      height: 30px;
      font-size: 12px;
      font-family: 'roboto', sans-serif;
      font-weight: normal;
      color: var(--tg-theme-button-text-color);
      text-align: center;
    `,
    CartInputContainer: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    `,
    CartInput: styled.input`
        border: 1px solid var(--tg-theme-hint-color);
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
        font-family: 'roboto', sans-serif;
        font-weight: normal;
        color: var(--tg-theme-text-color);
        background-color: var(--tg-theme-bg-color);
        margin-bottom: 10px;
      
        &:focus {
          outline: none;
          border-color: var(--tg-theme-button-color);
        }
    `,
}

export default css;

import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    userId: null,
}


const infoSlice = createSlice({
    name: "info",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.userId = action.payload;
        }
    }
})


export const { addUser } = infoSlice.actions;
export default infoSlice.reducer;

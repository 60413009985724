import React from 'react';
import css from '../../styles/main.css';

const { StartCard, StartCardImg, StartCardTitle } = css;

const Card = (props) => {
    return (
        <React.Fragment>
            <StartCard>
                <StartCardImg src={props.img} />
                <StartCardTitle>{props.title}</StartCardTitle>
            </StartCard>
        </React.Fragment>
    );
}

export default Card;

import React from "react";
import css from "../../styles/main.css";
import { removeFromCart, addToCart } from "../../redux-state/reducers/Cart";
import { useDispatch } from "react-redux";

const { CartItem, CartItemImg, CartItemTitle, CartItemPriceContainer, CartItems,
    CartItemCartButtonContainer, CartItemCartButton, CartItemPrice, CartItemButton,
    CartItemCartQuantity} = css;

const CartItemComp = (props) => {
    const dispatch = useDispatch();

    const item = props.item;
    const amount = props.amount;
    return (
        <React.Fragment>
            {item
            ? (
                <CartItem>
                    <CartItemImg src={item.image} alt={item.name} />
                    <CartItemTitle>{item.name}</CartItemTitle>
                    <CartItemPriceContainer>
                        <CartItemPrice>{item.price} руб.</CartItemPrice>
                        <CartItemCartButtonContainer>
                            <CartItemCartButton className="minus" onClick={() => dispatch(removeFromCart(item))}>-</CartItemCartButton>
                            <CartItemCartQuantity>{amount}</CartItemCartQuantity>
                            <CartItemCartButton className="plus" onClick={() => dispatch(addToCart(item))}>+</CartItemCartButton>
                        </CartItemCartButtonContainer>
                    </CartItemPriceContainer>
                </CartItem>
                )
            : null}
        </React.Fragment>
    )
}


export default CartItemComp;

import React from "react";
import css from "../../styles/main.css";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux-state/reducers/Cart";

const { ItemCartButtonContainer, ItemCartButton, ItemCartQuantity } = css;

const CartManage = (props) => {
    const cart = useSelector((state) => state.cart.cart);
    const dispatch = useDispatch();
    return (
        <>
            <ItemCartButtonContainer>
                <ItemCartButton className="minus" onClick={() => dispatch(removeFromCart(props.item))}>-</ItemCartButton>
                <ItemCartQuantity>{cart[props.item.id] ? cart[props.item.id].count : 0}</ItemCartQuantity>
                <ItemCartButton className="plus" onClick={() => dispatch(addToCart(props.item))}>+</ItemCartButton>
            </ItemCartButtonContainer>

        </>
    )
}


export default CartManage;

import React from "react";
import css from "../../styles/main.css";

const { HeaderNav, HeaderButtonContainer, HeaderButton} = css;

const Header = (props) => {
    return (
        <>
            <HeaderNav>
                <HeaderButtonContainer>
                    {props.categories.map((category) => {
                        return (
                            <HeaderButton activeClass="active" to={`category${category.id}`} spy={true} smooth={true} offset={-50} duration={500}>{category.name}</HeaderButton>
                        )
                    })}
                </HeaderButtonContainer>
            </HeaderNav>
        </>
    )
    }

export default Header;

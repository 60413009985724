import { createGlobalStyle } from 'styled-components';

import RobotoRegular from './../fonts/Roboto-Regular.ttf';
import RobotoBold from './../fonts/Roboto-Bold.ttf';
import RobotoItalic from './../fonts/Roboto-Italic.ttf';

export default createGlobalStyle`

@font-face {
    font-family: 'roboto';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(${RobotoRegular}) format('truetype'),
}

    @font-face {
        font-family: 'roboto';
        font-weight: bold;
        font-style: normal;
        font-display: swap;
        src: url(${RobotoBold}) format('truetype'),
    }

    @font-face {
        font-family: 'roboto';
        font-weight: normal;
        font-style: italic;
        font-display: swap;
        src: url(${RobotoItalic}) format('truetype'),
    }

`;
import React from "react";
import css from "../../styles/main.css";
import trashImg from "../../img/trash.svg";
import { clearCart} from "../../redux-state/reducers/Cart";
import { useDispatch } from "react-redux";

const { CartHeader, CartTitle, CartTrash } = css;

const CartHeaderComp = () => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <CartHeader>
                <CartTitle>Ваш заказ</CartTitle>
                <CartTrash src={trashImg} alt="trash" onClick={() => dispatch(clearCart())} />
            </CartHeader>
        </React.Fragment>
   )
}
export default CartHeaderComp;

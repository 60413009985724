import React from 'react';
import css from '../../styles/main.css';
import Item from './Item';

const { CategoryTitle } = css;

const ItemCard = (props) => {
    const { categories } = props;

    return (
        <React.Fragment>
            {categories.map((category) => {
                return (
                    <>
                        <div id={`category${category.id}`}>
                            <CategoryTitle key={category.id}>{category.name}</CategoryTitle>
                            <Item items={category.items} categoryId={category.id} />
                        </div>
                    </>
                )
            })}

        </React.Fragment>
    )
}

export default ItemCard;

import React, { useEffect } from 'react';
import css from '../../styles/main.css';
import CartManage from './CartManage';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../redux-state/reducers/Cart";

const { ItemRow, ItemContainer, ItemImg, ItemInfo, ItemTitle, ItemDescription, 
    ItemPriceContainer, ItemPrice, ItemButton } = css;

const Item = (props) => {
    const { items } = props;
    const cart = useSelector((state) => state.cart.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const goToItemInfo = (item) => {
        navigate(`/itemInfo/${item.id}`);
    }

    function truncateString(str) {
        if (str) {
            if (str.length <= 60) {
                return str;
            } else {
                return str.slice(0, 60);
            }
        }
    }

    return (
        <React.Fragment>
            {items.map((item) => {
                return (
                    <ItemRow>
                        <ItemContainer>
                            <ItemImg src={item.image} onClick={() => goToItemInfo(item)} />
                            <ItemInfo onClick={() => goToItemInfo(item)} >
                                <ItemTitle>{item.name}</ItemTitle>
                                <ItemDescription>{truncateString(item.description)}</ItemDescription>
                            </ItemInfo>
                            <ItemPriceContainer>
                                <ItemPrice>{item.price} руб.</ItemPrice>
                                {
                                    (cart[item.id]) && <CartManage item={item}/>
                                }
                                {
                                    (!cart[item.id]) && <ItemButton onClick={() => dispatch(addToCart(item))}>Добавить</ItemButton>
                                }
                            </ItemPriceContainer>
                        </ItemContainer>
                    </ItemRow>
                )
            })}
        </React.Fragment>
    )
}

export default Item;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import css from "../../styles/main.css";
import axios from "axios";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToOrder } from "../../redux-state/reducers/Cart";

const { ItemIDiv, ItemIContainer, ItemIImg, ItemITitle, ItemIDescription, ItemIComposition } = css;

const ItemInfo = () => {

    const apiUrl = config.apiURL;
    const { itemId } = useParams();
    const itemUrl = apiUrl + 'item/' + itemId;
    const [item, setItem] = useState(null);
    const updateItem = async () => {
        const result = await axios.get(itemUrl);
        setItem(result.data);
    };
    updateItem();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tg = window.Telegram.WebApp;
    const mainButton = tg.MainButton;
    mainButton.color = "#62C751"
    mainButton.textColor = "#FFFFFF"

    function addCart() {
        dispatch(addToOrder(item));
        navigate(-1);
    }

    useEffect(() => {
        if (item) {
            mainButton.text = `Добавить ${item.price} руб.`
            mainButton.show();
            mainButton.onClick(addCart);

            return () => {
                mainButton.offClick(addCart);
            }
        }
    }, [item]);

    return (
        <React.Fragment>
            <ItemIDiv>
                { item != null
                    ? (
                        <>
                            <ItemIImg src={item.image} />
                            <ItemIContainer>
                                <ItemITitle>{item.name}</ItemITitle>
                                <ItemIDescription>{item.description}</ItemIDescription>
                                <ItemIComposition>{item.composition}</ItemIComposition>
                            </ItemIContainer>
                        </>
                    )
                    : null
                }
            </ItemIDiv>
        </React.Fragment>
    )
}

export default ItemInfo;

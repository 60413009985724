import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: {},
}


const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const item = action.payload;
            if (state.cart[item.id]) {
                state.cart[item.id].count += 1;
            } else {
                state.cart[item.id] = {
                    count: 1,
                }
            }
        },
        addToOrder: (state, action) => {
            const item = action.payload;
            if (!(item.id in state.cart)) {
                state.cart[item.id] = {
                    count: 1,
                }
            }
        },
        removeFromCart: (state, action) => {
            const item = action.payload;
            if (state.cart[item.id].count === 1) {
                delete state.cart[item.id];
            } else {
                state.cart[item.id].count -= 1;
            }
        },
        clearCart: (state) => {
            state.cart = {};
        },
        addItemObjToCart: (state, action) => {
            const item = action.payload;
            if (!("item" in state.cart[item.id])) {
            state.cart[item.id] = {
                ...state.cart[item.id],
                item: item}
            }
        }
    }
})

export const { addToCart , removeFromCart, clearCart, addToOrder  , addItemObjToCart} = cartSlice.actions;
export default cartSlice.reducer;
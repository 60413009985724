import React, { useEffect, useState } from "react";
import css from "../../styles/main.css";
import CartHeaderComp from "../comps/CartHeaderComp";
import { useSelector, useDispatch } from "react-redux";
import CartItemComp from "../comps/CartItemComp";
import {useNavigate, useSearchParams} from "react-router-dom";
import { config } from "../../config";
import axios from "axios";
import {addItemObjToCart} from "../../redux-state/reducers/Cart";

const { CartDiv, CartItems, CartInner, OrderTypeHeader, CartInputContainer, CartInput} = css;

const ItemCart = () => {

    const cart = useSelector((state) => state.cart.cart);
    const userId = useSelector((state) => state.info.userId);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tg = window.Telegram.WebApp;
    const [params, ] = useSearchParams();
    const BotId = params.get('botId');
    const type = params.get('type');
    const typeOrder = type === 'cafe' ? 'В кафе' : type === 'delivery' ? 'Доставка' : 'Самовывоз';

    const [totalPrice, setTotalPrice] = useState(0);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [comment, setComment] = useState(null);
    const [table, setTable] = useState(null);
    const apiUrl = config.apiURL;

    useEffect(() => {
        const updateItem = async () => {
            let total = 0;
            Object.keys(cart).map(async (key) => {
                const itemUrl = apiUrl + 'item/' + key;
                const result = await axios.get(itemUrl);
                const itemObj = result.data;
                dispatch(addItemObjToCart(itemObj));
                total += cart[key].count * itemObj.price;
                setTotalPrice(total);
            });
        };
        updateItem();
    }, [cart]);

    const mainButton = tg.MainButton;
    mainButton.color = "#62C751"
    mainButton.textColor = "#FFFFFF"

    useEffect(() => {
        mainButton.text = `Заказать (${totalPrice} руб.)`;
        mainButton.onClick(sendOrder);
        mainButton.show();
        return () => {
            mainButton.offClick(sendOrder);
        }
    }, [totalPrice, name, phone, address, comment, table]);

    useEffect(() => {
        if (Object.keys(cart).length === 0) {
            navigate(-1);
        }
    }, [cart]);

    async function sendOrder() {
        mainButton.showProgress(false);
        const result = await axios.post(apiUrl + 'order/', {
            type: type,
            items: cart,
            phone: phone,
            address: address,
            comment: comment,
            table: table,
            name: name,
            botId: BotId,
            userId: userId
        })
        setTimeout(() => {
            tg.close();
        }, 2000);
    }

    return (
        <React.Fragment>
            <CartDiv>
                <CartInner>
                    <CartHeaderComp />
                    <CartItems>
                        {
                            Object.keys(cart).map((key) => {
                                return (
                                    <CartItemComp item={cart[key].item} amount={cart[key].count} />
                                )
                            })
                        }
                    </CartItems>
                </CartInner>
                <OrderTypeHeader>{typeOrder}</OrderTypeHeader>
                <CartInputContainer>
                    <CartInput placeholder="Имя" onChange={(e) => setName(e.target.value)} value={name} />
                    <CartInput placeholder="Телефон" onChange={(e) => setPhone(e.target.value)} value={phone} />
                    {type === 'delivery' && <CartInput placeholder="Адрес" onChange={(e) => setAddress(e.target.value)} value={address} />}
                    {type === 'cafe' && <CartInput placeholder="№ стола" onChange={(e) => setTable(e.target.value)} value={table} />}
                    <CartInput placeholder="Комментарий" onChange={(e) => setComment(e.target.value)} value={comment} />
                </CartInputContainer>
            </CartDiv>
        </React.Fragment>
    )
}


export default ItemCart;
